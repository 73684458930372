jQuery(document).ready(function () {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			'X-Requested-With': 'XMLHttpRequest'
		},
		xhrFields: {
			withCredentials: true
		},
		crossDomain: true
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$(".header-nav-toggle").on("click", function (e) {
		e.preventDefault();
		if (!$(e.target).is(":visible")) {
			return;
		}

		$(".header-nav, .header-nav-toggle").toggleClass("open");
	});

	$('.modal').on('show.bs.modal', function () {
		$('.modal').modal('hide');
	});

	$('.karmester-lista').on('click', '.szavazasgomb', function () {
		$('#szavazasForm').attr('action', $(this).data('href'));
	});

	$('.karmester').on('click', '.szavazasgomb', function () {
		$('#szavazasForm').attr('action', $(this).data('href'));
	});

	/*$('form.validate').each(function() {
		var $this = $(this);
		$this.validate({
			ignore: [],
			validClass: '',
			errorElement: 'span',
			errorClass: 'validate-msg has-error',
			errorPlacement: function(error, element) {
				if(element.attr("type").match(/radio|checkbox/i)){
					element.next("label").after(error);
				} else {
					error.insertAfter(element);
				}
			}
		});
	});*/
	$('#regGomb').prop('disabled', true);
	$('#regForm').on('change', '#jatekszabalyzat', function () {
		$this = $(this);
		if ($this.is(":checked")) {
			$('#regGomb').prop('disabled', false);
		}
		else {
			$('#regGomb').prop('disabled', true);
		}
	});

	$('#szavazas').on('hide.bs.modal', function () {
		$('#koszonjuk').remove()
	})

});

function submitRegForm(token) {
	document.getElementById('regForm').submit();
}

function submitLoginForm(token) {
	document.getElementById('loginForm').submit();
}

function submitSzavazasForm(token) {
	document.getElementById('szavazasForm').submit();
}

$(document).on('submit', '#szavazasForm', function (e) {
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('action') || $this.data('action'),
		method: $this.data('method') || 'POST',
		data: $this.formSerialize(),
		contentType: 'html',
		success: function (resp) {
			$('#szavazas .modal-body').html(resp);
		}
	});
});
